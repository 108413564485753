import { useEffect, useRef, useState } from "react";
import { getOpenAI } from "../services/api";
import { textBoxes } from "../mocks/textboxMock";

const TextBox = ({
  title,
  forID,
  val,
  handleChange,
  para = true,
  trans,
  orgVal,
  handleTextGo,
  refresh,
}) => {
  const [local, setLocal] = useState("");
  const [loading, setLoading] = useState(true);
  const textRef = useRef(null);

  useEffect(() => {
    async function startMe() {
      if (para && val === "" && orgVal) {
        setLoading(true);
        const textBox = textRef.current;
        let response = null;
        if (trans === "English") {
          response = await getOpenAI(orgVal, trans, 1);
        } else {
          response = await getOpenAI(orgVal, trans);
        }
        handleChange({ target: { id: textBox.id, value: response } });
        setLocal(response);
        setLoading(false);
      } else {
        setLocal(val);
        setLoading(false);
      }
    }
    startMe();
  }, [trans, refresh, para]);

  async function handleSummarize() {
    setLoading(true);
    let text = local;
    let res = await getOpenAI(text, trans, 2);
    const textBox = textRef.current;
    setLoading(false);
    handleChange({ target: { id: textBox.id, value: res } });
    setLocal(res);
  }

  useEffect(() => {}, [loading]);

  async function handleParaphrase() {
    setLoading(true);
    const textarea = textRef.current;
    let aux = textarea.value;
    let text = textarea.value;
    let res = null;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    if (start !== end) {
      text = aux.substring(start, end);
    }
    res = await getOpenAI(text, trans, 1);

    if (start !== end) {
      aux = aux.substring(0, start) + " " + res + " " + aux.substring(end);
    } else {
      aux = res;
    }

    handleChange({ target: { id: textarea.id, value: aux } });
    setLoading(false);
    setLocal(aux);
  }

  function localChange(e) {
    setLocal(e.target.value);
    handleChange(e);
  }

  return (
    <>
      <div
        className={
          loading ? (forID !== "text" ? "halfFlex" : "customFlex") : "hidden"
        }
      >
        <div className="flex items-center justify-center w-full">
          <div className="w-[200px] h-[160px] relative">
            <div class="loader absolute top-[70px] left-[100px]"></div>
          </div>
        </div>
      </div>
      <div
        className={
          !loading ? (forID !== "text" ? "halfFlex" : "customFlex") : "hidden"
        }
      >
        <div
          className={
            forID !== "text"
              ? "flex items-center w-full flex-col"
              : "flex items-center w-full"
          }
        >
          <div
            className={
              forID !== "text"
                ? "labelDiv textLabelDiv"
                : "labelDiv textLabelDiv flex-col"
            }
          >
            <label htmlFor={forID}>{title}</label>
            {forID !== "text" ? (
              <>
                <button
                  className="block custombtn"
                  type="button"
                  onClick={() => handleParaphrase()}
                >
                  Paraphrase
                </button>
                <button
                  className="block custombtn"
                  type="button"
                  onClick={() => handleSummarize()}
                >
                  Summarize
                </button>
              </>
            ) : (
              <button
                className="block custombtn"
                type="button"
                onClick={() => handleTextGo()}
              >
                Go
              </button>
            )}
          </div>
          <div className="inputDiv">
            <textarea
              ref={textRef}
              id={forID}
              className="customInput"
              value={local}
              onChange={(e) => localChange(e)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TextBox;
