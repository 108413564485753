export const getPost = async (id) => {
  const response = await fetch(
    `https://news.energysalesdirect.com/api/v1/posts/${id}/`
  );
  const result = await response.json();
  return {
    ...result,
    source: result.source.title,
    folder: result.folder && result.folder.replaceAll("/", ""),
    fotourl: result.fotourl,
    source: result.source.title,
  };
};

export const getCountries = async () => {
  const response = await fetch(
    "https://news.energysalesdirect.com/api/v1/countries/data/"
  );
  const result = await response.json();
  return result;
};

export const PostNews = async (data, another) => {
  //delete data.source;
  let method = data.id ? "PUT" : "POST";
  await fetch("https://news.energysalesdirect.com/api/v1/cockpit/emp_news/", {
    method: method,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((r) => r.json())
    .then((d) => {
      if (another) {
        localStorage.setItem("newstitle", d.title);
        localStorage.setItem("newsid", d.id);
        window.location.href = "/newpostedit/0";
      } else {
        localStorage.removeItem("newstitle");
        localStorage.removeItem("newsid");
        window.location.href = "/admin/posts/post/";
      }
    });
};

export async function EmpLogin(obj) {
  return await fetch(
    "https://www.energymarketprice.com/siteapi/Administration/account/token",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    }
  )
    .then((r) => r.json())
    .then((data) => data);
}

export async function ValidateToken(obj) {
  return await fetch(
    "https://cockpit.energymarketprice.com/api/Auth/TokenValidation/",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    }
  )
    .then((r) => r.json())
    .then((d) => d);
}

export async function getOpenAI(text, lang, type = 0, title = 0) {
  let extra = "and split paragraphs with <br/><br/>";
  if (title) extra = "";

  let token = window.localStorage.getItem("authToken");
  let localText = `Translate in ${lang}, make response object with key 'translation' ${extra} for this text: ${text}`;
  let response = null;
  if (type === 1)
    localText = `Paraphrase in ${lang}, make response object with key 'translation' ${extra} for this text: ${text}`;
  if (type === 2)
    localText = `Summarize in ${lang}, make response object with key 'translation' ${extra} for this text: ${text}`;

  await fetch("https://news.energysalesdirect.com/api/v1/cockpit/openai/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      text: localText,
    }),
  })
    .then((r) => r.json())
    .then((data) => {
      let text = data.response;
      let res = null;
      try {
        res = JSON.parse(text);
      } catch (e) {
        text += '"}';
        res = JSON.parse(text);
      }
      response = res.translation;
    });

  return response;
}
