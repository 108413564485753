export const textBoxes = [
  {
    title: "Original Text:",
    forID: "text",
    val: "text",
    para: false,
  },
  {
    title: "Formated EN:",
    forID: "validated_en",
    trans: "English",
  },
  {
    title: "Formated FR:",
    forID: "validated_fr",
    trans: "French",
  },
  {
    title: "Formated DU:",
    forID: "validated_nl",
    trans: "Dutch",
  },
  {
    title: "Formated GE:",
    forID: "validated_de",
    trans: "German",
  },
  {
    title: "Formated IT:",
    forID: "validated_it",
    trans: "Italy",
  },
  {
    title: "Formated SP:",
    forID: "validated_sp",
    trans: "Spain",
  },
];
