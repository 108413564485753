export const titleArr = [
  {
    title: "Title",
    forID: "title",
    val: "title",
    extra: false,
  },
  {
    title: "Title EN",
    forID: "title_en",
    lang: "English",
    extra: true,
  },
  {
    title: "Title FR",
    forID: "title_fr",
    lang: "French",
    extra: true,
  },
  {
    title: "Title DU",
    forID: "title_nl",
    lang: "Dutch",
    extra: true,
  },
  {
    title: "Title GE",
    forID: "title_de",
    lang: "German",
    extra: true,
  },
  {
    title: "Title IT",
    forID: "title_it",
    lang: "Italy",
    extra: true,
  },
  {
    title: "Title SP",
    forID: "title_sp",
    lang: "Spain",
    extra: true,
  },
];
