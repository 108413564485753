const Selector = ({ value, text, forID, title, handleChange, selected }) => {
  const localClass =
    forID === "countries"
      ? "p-2 border rounded-md h-[150px]"
      : "p-2 border rounded-md";
  const localOpt = forID === "countries" ? true : false;
  return (
    <div className="customFlex">
      <div className="flex items-center">
        <label
          className="w-[220px] px-4 font-semibold cursor-pointer"
          htmlFor={forID}
        >
          {title}
        </label>
      </div>
      <select
        className={localClass}
        id={forID}
        multiple={localOpt}
        value={selected}
        onChange={(e) => handleChange(e)}
      >
        {value.map((i, j) => (
          <option
            key={`${forID}${i}`}
            value={i}
            selected={
              forID === "countries"
                ? selected.includes(i)
                : selected.toLowerCase() === i.toLowerCase()
            }
          >
            {text[j]}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Selector;
